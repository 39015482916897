<template>
  <div
    class="relative flex min-h-screen flex-col justify-between bg-gradient-to-b from-ocean to-main-bg pt-16 lg:pt-32"
  >
    <AppHeader class="fixed top-0 z-50 w-full" />
    <main>
      <slot />
    </main>

    <footer>
      <AppFooter />
    </footer>
  </div>
</template>
